import { WireDataStatus } from './../../../../common/interfaces/collection'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'collection'

export const LIST_CHECKS_REQUEST = `${prefix}/LIST_CHECKS_REQUEST`
export const LIST_CHECKS_SUCCESS = `${prefix}/LIST_CHECKS_SUCCESS`
export const LIST_CHECKS_FAILURE = `${prefix}/LIST_CHECKS_FAILURE`

export const listChecks: CommonPromiseAction = (params?: object) => ({
  type: [LIST_CHECKS_REQUEST, LIST_CHECKS_SUCCESS, LIST_CHECKS_FAILURE],
  promise: (api: IApi) => api.collection.listChecks(params),
  params,
})

const GET_CHECKS_SUMMARY_INFO = createRequestTypes(prefix, 'GET_CHECKS_SUMMARY_INFO')

export const [
  GET_CHECKS_SUMMARY_INFO_REQUEST,
  GET_CHECKS_SUMMARY_INFO_SUCCESS,
  GET_CHECKS_SUMMARY_INFO_FAILURE,
] = GET_CHECKS_SUMMARY_INFO

export const getChecksSummaryInfo: CommonPromiseAction = (data: object) => ({
  type: GET_CHECKS_SUMMARY_INFO,
  promise: (api: IApi) => api.collection.getChecksSummaryInfo(data),
})

export const UPLOAD_CHECKS_REQUEST = `${prefix}/UPLOAD_CHECKS_REQUEST`
export const UPLOAD_CHECKS_SUCCESS = `${prefix}/UPLOAD_CHECKS_SUCCESS`
export const UPLOAD_CHECKS_FAILURE = `${prefix}/UPLOAD_CHECKS_FAILURE`

export const uploadChecks: CommonPromiseAction = (data: FormData) => ({
  type: [UPLOAD_CHECKS_REQUEST, UPLOAD_CHECKS_SUCCESS, UPLOAD_CHECKS_FAILURE],
  promise: (api: IApi) => api.collection.uploadChecks(data),
})

export const SAVE_CHECKS_REQUEST = `${prefix}/SAVE_CHECKS_REQUEST`
export const SAVE_CHECKS_SUCCESS = `${prefix}/SAVE_CHECKS_SUCCESS`
export const SAVE_CHECKS_FAILURE = `${prefix}/SAVE_CHECKS_FAILURE`

export const saveChecks: CommonPromiseAction = (data: object) => ({
  type: [SAVE_CHECKS_REQUEST, SAVE_CHECKS_SUCCESS, SAVE_CHECKS_FAILURE],
  promise: (api: IApi) => api.collection.saveChecks(data),
})

export const UPDATE_CHECK_REQUEST = `${prefix}/UPDATE_CHECK_REQUEST`
export const UPDATE_CHECK_SUCCESS = `${prefix}/UPDATE_CHECK_SUCCESS`
export const UPDATE_CHECK_FAILURE = `${prefix}/UPDATE_CHECK_FAILURE`

export const updateCheck: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_CHECK_REQUEST, UPDATE_CHECK_SUCCESS, UPDATE_CHECK_FAILURE],
  promise: (api: IApi) => api.collection.updateCheck(id, data),
})

export const UPDATE_CHECKS_REQUEST = `${prefix}/UPDATE_CHECKS_REQUEST`
export const UPDATE_CHECKS_SUCCESS = `${prefix}/UPDATE_CHECKS_SUCCESS`
export const UPDATE_CHECKS_FAILURE = `${prefix}/UPDATE_CHECKS_FAILURE`

export const updateChecks: CommonPromiseAction = (data: object) => ({
  type: [UPDATE_CHECKS_REQUEST, UPDATE_CHECKS_SUCCESS, UPDATE_CHECKS_FAILURE],
  promise: (api: IApi) => api.collection.updateChecks(data),
})

export const RESTORE_CHECK_REQUEST = `${prefix}/RESTORE_CHECK_REQUEST`
export const RESTORE_CHECK_SUCCESS = `${prefix}/RESTORE_CHECK_SUCCESS`
export const RESTORE_CHECK_FAILURE = `${prefix}/RESTORE_CHECK_FAILURE`

export const restoreCheck: CommonPromiseAction = (id: string) => ({
  type: [RESTORE_CHECK_REQUEST, RESTORE_CHECK_SUCCESS, RESTORE_CHECK_FAILURE],
  promise: (api: IApi) => api.collection.restoreCheck(id),
})

export const DELETE_CHECK_REQUEST = `${prefix}/DELETE_CHECK_REQUEST`
export const DELETE_CHECK_SUCCESS = `${prefix}/DELETE_CHECK_SUCCESS`
export const DELETE_CHECK_FAILURE = `${prefix}/DELETE_CHECK_FAILURE`

export const deleteCheck: CommonPromiseAction = (id: string, data: object) => ({
  type: [DELETE_CHECK_REQUEST, DELETE_CHECK_SUCCESS, DELETE_CHECK_FAILURE],
  promise: (api: IApi) => api.collection.deleteCheck(id, data),
})

export const ADD_CHECK_ACCOUNT_REQUEST = `${prefix}/ADD_CHECK_ACCOUNT_REQUEST`
export const ADD_CHECK_ACCOUNT_SUCCESS = `${prefix}/ADD_CHECK_ACCOUNT_SUCCESS`
export const ADD_CHECK_ACCOUNT_FAILURE = `${prefix}/ADD_CHECK_ACCOUNT_FAILURE`

export const addCheckAccount: CommonPromiseAction = (data: object) => ({
  type: [ADD_CHECK_ACCOUNT_REQUEST, ADD_CHECK_ACCOUNT_SUCCESS, ADD_CHECK_ACCOUNT_FAILURE],
  promise: (api: IApi) => api.collection.addCheckAccount(data),
  // @ts-ignore
  checkId: data.checkId,
})

export const LIST_CHECK_ACCOUNTS_REQUEST = `${prefix}/LIST_CHECK_ACCOUNTS_REQUEST`
export const LIST_CHECK_ACCOUNTS_SUCCESS = `${prefix}/LIST_CHECK_ACCOUNTS_SUCCESS`
export const LIST_CHECK_ACCOUNTS_FAILURE = `${prefix}/LIST_CHECK_ACCOUNTS_FAILURE`

export const listCheckAccounts: CommonPromiseAction = () => ({
  type: [LIST_CHECK_ACCOUNTS_REQUEST, LIST_CHECK_ACCOUNTS_SUCCESS, LIST_CHECK_ACCOUNTS_FAILURE],
  promise: (api: IApi) => api.collection.listCheckAccounts(),
})

export const LIST_NEW_WIRES_REQUEST = `${prefix}/LIST_NEW_WIRES_REQUEST`
export const LIST_NEW_WIRES_SUCCESS = `${prefix}/LIST_NEW_WIRES_SUCCESS`
export const LIST_NEW_WIRES_FAILURE = `${prefix}/LIST_NEW_WIRES_FAILURE`

export const listNewWires: CommonPromiseAction = (params?: object) => ({
  type: [LIST_NEW_WIRES_REQUEST, LIST_NEW_WIRES_SUCCESS, LIST_NEW_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.listWires({ ...params, status: WireDataStatus.New }),
  //@ts-ignore
  params: params,
})

export const LIST_NEGATIVE_WIRES_REQUEST = `${prefix}/LIST_NEGATIVE_WIRES_REQUEST`
export const LIST_NEGATIVE_WIRES_SUCCESS = `${prefix}/LIST_NEGATIVE_WIRES_SUCCESS`
export const LIST_NEGATIVE_WIRES_FAILURE = `${prefix}/LIST_NEGATIVE_WIRES_FAILURE`

export const listNegativeWires: CommonPromiseAction = (params?: object) => ({
  type: [LIST_NEGATIVE_WIRES_REQUEST, LIST_NEGATIVE_WIRES_SUCCESS, LIST_NEGATIVE_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.listWires({ ...params, status: WireDataStatus.Negative }),
  //@ts-ignore
  params: params,
})

export const LIST_DELETED_WIRES_REQUEST = `${prefix}/LIST_DELETED_WIRES_REQUEST`
export const LIST_DELETED_WIRES_SUCCESS = `${prefix}/LIST_DELETED_WIRES_SUCCESS`
export const LIST_DELETED_WIRES_FAILURE = `${prefix}/LIST_DELETED_WIRES_FAILURE`

export const listDeletedWires: CommonPromiseAction = (params?: object) => ({
  type: [LIST_DELETED_WIRES_REQUEST, LIST_DELETED_WIRES_SUCCESS, LIST_DELETED_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.listWires({ ...params, status: WireDataStatus.Deleted }),
  //@ts-ignore
  params: params,
})

export const UPLOAD_WIRES_REQUEST = `${prefix}/UPLOAD_WIRES_REQUEST`
export const UPLOAD_WIRES_SUCCESS = `${prefix}/UPLOAD_WIRES_SUCCESS`
export const UPLOAD_WIRES_FAILURE = `${prefix}/UPLOAD_WIRES_FAILURE`

export const uploadWires: CommonPromiseAction = (data: FormData) => ({
  type: [UPLOAD_WIRES_REQUEST, UPLOAD_WIRES_SUCCESS, UPLOAD_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.uploadWires(data),
})

export const SAVE_WIRES_REQUEST = `${prefix}/SAVE_WIRES_REQUEST`
export const SAVE_WIRES_SUCCESS = `${prefix}/SAVE_WIRES_SUCCESS`
export const SAVE_WIRES_FAILURE = `${prefix}/SAVE_WIRES_FAILURE`

export const saveWires: CommonPromiseAction = (data: object) => ({
  type: [SAVE_WIRES_REQUEST, SAVE_WIRES_SUCCESS, SAVE_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.saveWires(data),
})

export const UPDATE_WIRE_REQUEST = `${prefix}/UPDATE_WIRE_REQUEST`
export const UPDATE_WIRE_SUCCESS = `${prefix}/UPDATE_WIRE_SUCCESS`
export const UPDATE_WIRE_FAILURE = `${prefix}/UPDATE_WIRE_FAILURE`

export const updateWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_WIRE_REQUEST, UPDATE_WIRE_SUCCESS, UPDATE_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.updateWire(id, data),
  params: data,
})

export const UPDATE_WIRES_REQUEST = `${prefix}/UPDATE_WIRES_REQUEST`
export const UPDATE_WIRES_SUCCESS = `${prefix}/UPDATE_WIRES_SUCCESS`
export const UPDATE_WIRES_FAILURE = `${prefix}/UPDATE_WIRES_FAILURE`

export const updateWires: CommonPromiseAction = (data: object) => ({
  type: [UPDATE_WIRES_REQUEST, UPDATE_WIRES_SUCCESS, UPDATE_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.updateWires(data),
  params: data,
})

export const RESTORE_WIRE_REQUEST = `${prefix}/RESTORE_WIRE_REQUEST`
export const RESTORE_WIRE_SUCCESS = `${prefix}/RESTORE_WIRE_SUCCESS`
export const RESTORE_WIRE_FAILURE = `${prefix}/RESTORE_WIRE_FAILURE`

export const restoreWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [RESTORE_WIRE_REQUEST, RESTORE_WIRE_SUCCESS, RESTORE_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.restoreWire(id),
  params: data,
})

export const DELETE_WIRE_REQUEST = `${prefix}/DELETE_WIRE_REQUEST`
export const DELETE_WIRE_SUCCESS = `${prefix}/DELETE_WIRE_SUCCESS`
export const DELETE_WIRE_FAILURE = `${prefix}/DELETE_WIRE_FAILURE`

export const deleteWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [DELETE_WIRE_REQUEST, DELETE_WIRE_SUCCESS, DELETE_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.deleteWire(id, data),
})

export const LIST_AGGREGATION_REQUEST = `${prefix}/LIST_AGGREGATION_REQUEST`
export const LIST_AGGREGATION_SUCCESS = `${prefix}/LIST_AGGREGATION_SUCCESS`
export const LIST_AGGREGATION_FAILURE = `${prefix}/LIST_AGGREGATION_FAILURE`

export const listAggregation: CommonPromiseAction = (params?: {
  page?: number
  filters?: object
}) => ({
  type: [LIST_AGGREGATION_REQUEST, LIST_AGGREGATION_SUCCESS, LIST_AGGREGATION_FAILURE],
  promise: (api: IApi) => api.collection.listAggregation(params),
  // @ts-ignore
  loadMore: params.loadMore,
})

export const EXPORT_AGGREGATION_REQUEST = `${prefix}/EXPORT_AGGREGATION_REQUEST`
export const EXPORT_AGGREGATION_SUCCESS = `${prefix}/EXPORT_AGGREGATION_SUCCESS`
export const EXPORT_AGGREGATION_FAILURE = `${prefix}/EXPORT_AGGREGATION_FAILURE`

export const exportAggregation: CommonPromiseAction = (params?: {
  filters?: object
  orderBy?: string
  orderDirection?: string
}) => ({
  type: [EXPORT_AGGREGATION_REQUEST, EXPORT_AGGREGATION_SUCCESS, EXPORT_AGGREGATION_FAILURE],
  promise: (api: IApi) => api.collection.exportAggregation({ ...params, collectionsExport: true }),
  params,
})

export const LIST_AGGREGATION_ROW_REQUEST = `${prefix}/LIST_AGGREGATION_ROW_REQUEST`
export const LIST_AGGREGATION_ROW_SUCCESS = `${prefix}/LIST_AGGREGATION_ROW_SUCCESS`
export const LIST_AGGREGATION_ROW_FAILURE = `${prefix}/LIST_AGGREGATION_ROW_FAILURE`

export const listAggregationRow: CommonPromiseAction = (params: object) => ({
  type: [LIST_AGGREGATION_ROW_REQUEST, LIST_AGGREGATION_ROW_SUCCESS, LIST_AGGREGATION_ROW_FAILURE],
  promise: (api: IApi) => api.collection.listAggregationRow(params),
  params,
})

export const UPDATE_AGGREGATION_ROW_REQUEST = `${prefix}/UPDATE_AGGREGATION_ROW_REQUEST`
export const UPDATE_AGGREGATION_ROW_SUCCESS = `${prefix}/UPDATE_AGGREGATION_ROW_SUCCESS`
export const UPDATE_AGGREGATION_ROW_FAILURE = `${prefix}/UPDATE_AGGREGATION_ROW_FAILURE`

export const updateAggregationRow: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_AGGREGATION_ROW_REQUEST,
    UPDATE_AGGREGATION_ROW_SUCCESS,
    UPDATE_AGGREGATION_ROW_FAILURE,
  ],
  promise: (api: IApi) => api.collection.updateAggregationRow(id, data),
})

export const DELETE_AGGREGATION_ROW_REQUEST = `${prefix}/DELETE_AGGREGATION_ROW_REQUEST`
export const DELETE_AGGREGATION_ROW_SUCCESS = `${prefix}/DELETE_AGGREGATION_ROW_SUCCESS`
export const DELETE_AGGREGATION_ROW_FAILURE = `${prefix}/DELETE_AGGREGATION_ROW_FAILURE`

export const deleteAggregationRow: CommonPromiseAction = (data: object) => ({
  type: [
    DELETE_AGGREGATION_ROW_REQUEST,
    DELETE_AGGREGATION_ROW_SUCCESS,
    DELETE_AGGREGATION_ROW_FAILURE,
  ],
  promise: (api: IApi) => api.collection.deleteAggregationRow(data),
})

export const LIST_DELETED_ITEMS_REQUEST = `${prefix}/LIST_DELETED_ITEMS_REQUEST`
export const LIST_DELETED_ITEMS_SUCCESS = `${prefix}/LIST_DELETED_ITEMS_SUCCESS`
export const LIST_DELETED_ITEMS_FAILURE = `${prefix}/LIST_DELETED_ITEMS_FAILURE`

export const listDeletedItems: CommonPromiseAction = (params = {}) => ({
  type: [LIST_DELETED_ITEMS_REQUEST, LIST_DELETED_ITEMS_SUCCESS, LIST_DELETED_ITEMS_FAILURE],
  promise: (api: IApi) => api.collection.listDeletedItems(params),
  // @ts-ignore
  params: params,
})

export const SENT_DELETED_ITEM_REQUEST = `${prefix}/SENT_DELETED_ITEM_REQUEST`
export const SENT_DELETED_ITEM_SUCCESS = `${prefix}/SENT_DELETED_ITEM_SUCCESS`
export const SENT_DELETED_ITEM_FAILURE = `${prefix}/SENT_DELETED_ITEM_FAILURE`

export const sentDeletedItems: CommonPromiseAction = (data: object) => ({
  type: [SENT_DELETED_ITEM_REQUEST, SENT_DELETED_ITEM_SUCCESS, SENT_DELETED_ITEM_FAILURE],
  promise: (api: IApi) => api.collection.sentDeletedItems(data),
})

export const CREATE_DELETED_ITEM_REQUEST = `${prefix}/CREATE_DELETED_ITEM_REQUEST`
export const CREATE_DELETED_ITEM_SUCCESS = `${prefix}/CREATE_DELETED_ITEM_SUCCESS`
export const CREATE_DELETED_ITEM_FAILURE = `${prefix}/CREATE_DELETED_ITEM_FAILURE`

export const createDeletedItem: CommonPromiseAction = (data: object) => ({
  type: [CREATE_DELETED_ITEM_REQUEST, CREATE_DELETED_ITEM_SUCCESS, CREATE_DELETED_ITEM_FAILURE],
  promise: (api: IApi) => api.collection.createDeletedItem(data),
})

export const DELETE_DELETED_ITEM_REQUEST = `${prefix}/DELETE_DELETED_ITEM_REQUEST`
export const DELETE_DELETED_ITEM_SUCCESS = `${prefix}/DELETE_DELETED_ITEM_SUCCESS`
export const DELETE_DELETED_ITEM_FAILURE = `${prefix}/DELETE_DELETED_ITEM_FAILURE`

export const deleteDeletedItems: CommonPromiseAction = (data: object) => ({
  type: [DELETE_DELETED_ITEM_REQUEST, DELETE_DELETED_ITEM_SUCCESS, DELETE_DELETED_ITEM_FAILURE],
  promise: (api: IApi) => api.collection.deleteDeletedItems(data),
})

export const LIST_NEGATIVE_ITEMS_REQUEST = `${prefix}/LIST_NEGATIVE_ITEMS_REQUEST`
export const LIST_NEGATIVE_ITEMS_SUCCESS = `${prefix}/LIST_NEGATIVE_ITEMS_SUCCESS`
export const LIST_NEGATIVE_ITEMS_FAILURE = `${prefix}/LIST_NEGATIVE_ITEMS_FAILURE`

export const listNegativeItems: CommonPromiseAction = (params = {}) => ({
  type: [LIST_NEGATIVE_ITEMS_REQUEST, LIST_NEGATIVE_ITEMS_SUCCESS, LIST_NEGATIVE_ITEMS_FAILURE],
  promise: (api: IApi) => api.collection.listNegativeItems(params),
  // @ts-ignore
  params: params,
})

export const LIST_MID_DAY_WIRES_NEW_REQUEST = `${prefix}/LIST_MID_DAY_WIRES_NEW_REQUEST`
export const LIST_MID_DAY_WIRES_NEW_SUCCESS = `${prefix}/LIST_MID_DAY_WIRES_NEW_SUCCESS`
export const LIST_MID_DAY_WIRES_NEW_FAILURE = `${prefix}/LIST_MID_DAY_WIRES_NEW_FAILURE`

export const listMidDayWiresNew: CommonPromiseAction = (params?: object) => ({
  type: [
    LIST_MID_DAY_WIRES_NEW_REQUEST,
    LIST_MID_DAY_WIRES_NEW_SUCCESS,
    LIST_MID_DAY_WIRES_NEW_FAILURE,
  ],
  promise: (api: IApi) => api.collection.listMidDayWires({ ...params, status: WireDataStatus.New }),
  //@ts-ignore
  params: params,
})

export const LIST_MID_DAY_WIRES_NEGATIVE_REQUEST = `${prefix}/LIST_MID_DAY_WIRES_NEGATIVE_REQUEST`
export const LIST_MID_DAY_WIRES_NEGATIVE_SUCCESS = `${prefix}/LIST_MID_DAY_WIRES_NEGATIVE_SUCCESS`
export const LIST_MID_DAY_WIRES_NEGATIVE_FAILURE = `${prefix}/LIST_MID_DAY_WIRES_NEGATIVE_FAILURE`

export const listMidDayWiresNegative: CommonPromiseAction = (params?: object) => ({
  type: [
    LIST_MID_DAY_WIRES_NEGATIVE_REQUEST,
    LIST_MID_DAY_WIRES_NEGATIVE_SUCCESS,
    LIST_MID_DAY_WIRES_NEGATIVE_FAILURE,
  ],
  promise: (api: IApi) =>
    api.collection.listMidDayWires({ ...params, status: WireDataStatus.Negative }),
  //@ts-ignore
  params: params,
})

export const LIST_MID_DAY_WIRES_DELETED_REQUEST = `${prefix}/LIST_MID_DAY_WIRES_DELETED_REQUEST`
export const LIST_MID_DAY_WIRES_DELETED_SUCCESS = `${prefix}/LIST_MID_DAY_WIRES_DELETED_SUCCESS`
export const LIST_MID_DAY_WIRES_DELETED_FAILURE = `${prefix}/LIST_MID_DAY_WIRES_DELETED_FAILURE`

export const listMidDayWiresDeleted: CommonPromiseAction = (params?: object) => ({
  type: [
    LIST_MID_DAY_WIRES_DELETED_REQUEST,
    LIST_MID_DAY_WIRES_DELETED_SUCCESS,
    LIST_MID_DAY_WIRES_DELETED_FAILURE,
  ],
  promise: (api: IApi) =>
    api.collection.listMidDayWires({ ...params, status: WireDataStatus.Deleted }),
  //@ts-ignore
  params: params,
})

export const LIST_MID_DAY_WIRES_TOTALS_REQUEST = `${prefix}/LIST_MID_DAY_WIRES_TOTALS_REQUEST`
export const LIST_MID_DAY_WIRES_TOTALS_SUCCESS = `${prefix}/LIST_MID_DAY_WIRES_TOTALS_SUCCESS`
export const LIST_MID_DAY_WIRES_TOTALS_FAILURE = `${prefix}/LIST_MID_DAY_WIRES_TOTALS_FAILURE`

export const listMidDayWiresTotals: CommonPromiseAction = (params?: object) => ({
  type: [
    LIST_MID_DAY_WIRES_TOTALS_REQUEST,
    LIST_MID_DAY_WIRES_TOTALS_SUCCESS,
    LIST_MID_DAY_WIRES_TOTALS_FAILURE,
  ],
  promise: (api: IApi) => api.collection.listMidDayWiresTotals(params),
})

export const UPLOAD_MID_DAY_WIRES_REQUEST = `${prefix}/UPLOAD_MID_DAY_WIRES_REQUEST`
export const UPLOAD_MID_DAY_WIRES_SUCCESS = `${prefix}/UPLOAD_MID_DAY_WIRES_SUCCESS`
export const UPLOAD_MID_DAY_WIRES_FAILURE = `${prefix}/UPLOAD_MID_DAY_WIRES_FAILURE`

export const uploadMidDayWires: CommonPromiseAction = (data: FormData) => ({
  type: [UPLOAD_MID_DAY_WIRES_REQUEST, UPLOAD_MID_DAY_WIRES_SUCCESS, UPLOAD_MID_DAY_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.uploadMidDayWires(data),
})

export const SAVE_MID_DAY_WIRES_REQUEST = `${prefix}/SAVE_MID_DAY_WIRES_REQUEST`
export const SAVE_MID_DAY_WIRES_SUCCESS = `${prefix}/SAVE_MID_DAY_WIRES_SUCCESS`
export const SAVE_MID_DAY_WIRES_FAILURE = `${prefix}/SAVE_MID_DAY_WIRES_FAILURE`

export const saveMidDayWires: CommonPromiseAction = (data: object) => ({
  type: [SAVE_MID_DAY_WIRES_REQUEST, SAVE_MID_DAY_WIRES_SUCCESS, SAVE_MID_DAY_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.saveMidDayWires(data),
})

export const UPDATE_MID_DAY_WIRE_REQUEST = `${prefix}/UPDATE_MID_DAY_WIRE_REQUEST`
export const UPDATE_MID_DAY_WIRE_SUCCESS = `${prefix}/UPDATE_MID_DAY_WIRE_SUCCESS`
export const UPDATE_MID_DAY_WIRE_FAILURE = `${prefix}/UPDATE_MID_DAY_WIRE_FAILURE`

export const updateMidDayWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_MID_DAY_WIRE_REQUEST, UPDATE_MID_DAY_WIRE_SUCCESS, UPDATE_MID_DAY_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.updateWire(id, { ...data, midDay: true }),
  params: data,
})

export const UPDATE_MID_DAY_WIRES_REQUEST = `${prefix}/UPDATE_MID_DAY_WIRES_REQUEST`
export const UPDATE_MID_DAY_WIRES_SUCCESS = `${prefix}/UPDATE_MID_DAY_WIRES_SUCCESS`
export const UPDATE_MID_DAY_WIRES_FAILURE = `${prefix}/UPDATE_MID_DAY_WIRES_FAILURE`

export const updateMidDayWires: CommonPromiseAction = (data: object) => ({
  type: [UPDATE_MID_DAY_WIRES_REQUEST, UPDATE_MID_DAY_WIRES_SUCCESS, UPDATE_MID_DAY_WIRES_FAILURE],
  promise: (api: IApi) => api.collection.updateWires(data),
  params: data,
})

export const RESTORE_MID_DAY_WIRE_REQUEST = `${prefix}/RESTORE_MID_DAY_WIRE_REQUEST`
export const RESTORE_MID_DAY_WIRE_SUCCESS = `${prefix}/RESTORE_MID_DAY_WIRE_SUCCESS`
export const RESTORE_MID_DAY_WIRE_FAILURE = `${prefix}/RESTORE_MID_DAY_WIRE_FAILURE`

export const restoreMidDayWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [RESTORE_MID_DAY_WIRE_REQUEST, RESTORE_MID_DAY_WIRE_SUCCESS, RESTORE_MID_DAY_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.restoreMidDayWire(id),
  params: data,
})

export const DELETE_MID_DAY_WIRE_REQUEST = `${prefix}/DELETE_MID_DAY_WIRE_REQUEST`
export const DELETE_MID_DAY_WIRE_SUCCESS = `${prefix}/DELETE_MID_DAY_WIRE_SUCCESS`
export const DELETE_MID_DAY_WIRE_FAILURE = `${prefix}/DELETE_MID_DAY_WIRE_FAILURE`

export const deleteMidDayWire: CommonPromiseAction = (id: string, data: object) => ({
  type: [DELETE_MID_DAY_WIRE_REQUEST, DELETE_MID_DAY_WIRE_SUCCESS, DELETE_MID_DAY_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.deleteMidDayWire(id, data),
  params: data,
})

export const CREATE_WIRE_REQUEST = `${prefix}/CREATE_WIRE_REQUEST`
export const CREATE_WIRE_SUCCESS = `${prefix}/CREATE_WIRE_SUCCESS`
export const CREATE_WIRE_FAILURE = `${prefix}/CREATE_WIRE_FAILURE`

export const createWire: CommonPromiseAction = (data: object) => ({
  type: [CREATE_WIRE_REQUEST, CREATE_WIRE_SUCCESS, CREATE_WIRE_FAILURE],
  promise: (api: IApi) => api.collection.createWire(data),
})

export const LIST_WIRE_FILES_AND_STATUS_REQUEST = `${prefix}/LIST_WIRE_FILES_AND_STATUS_REQUEST`
export const LIST_WIRE_FILES_AND_STATUS_SUCCESS = `${prefix}/LIST_WIRE_FILES_AND_STATUS_SUCCESS`
export const LIST_WIRE_FILES_AND_STATUS_FAILURE = `${prefix}/LIST_WIRE_FILES_AND_STATUS_FAILURE`

export const listWireFilesAndStatus: CommonPromiseAction = (id: string) => ({
  type: [
    LIST_WIRE_FILES_AND_STATUS_REQUEST,
    LIST_WIRE_FILES_AND_STATUS_SUCCESS,
    LIST_WIRE_FILES_AND_STATUS_FAILURE,
  ],
  promise: (api: IApi) => api.collection.listWireFilesAndStatus(id),
})

export const CREATE_CHECK_REQUEST = `${prefix}/CREATE_CHECK_REQUEST`
export const CREATE_CHECK_SUCCESS = `${prefix}/CREATE_CHECK_SUCCESS`
export const CREATE_CHECK_FAILURE = `${prefix}/CREATE_CHECK_FAILURE`

export const createCheck: CommonPromiseAction = (data: object) => ({
  type: [CREATE_CHECK_REQUEST, CREATE_CHECK_SUCCESS, CREATE_CHECK_FAILURE],
  promise: (api: IApi) => api.collection.createCheck(data),
})

const LOAD_CHECK_IMAGE = createRequestTypes(prefix, 'LOAD_CHECK_IMAGE')

export const loadCheckImage: CommonPromiseAction = (id: string) => ({
  type: LOAD_CHECK_IMAGE,
  promise: (api: IApi) => api.collection.loadCheckImage(id),
})
