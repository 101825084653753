import React, { useCallback, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'

import styles from './UWDefaultNotes.module.scss'

import { ReactComponent as ChevronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as ChevronDown } from '@assets/images/down-chevron.svg'
import { IDefaultNote } from '@common/interfaces/dueDiligence'
import Menu from '../Common/Menu'
import UWDefaultNotesItem from './UWDefaultNotesItem'

interface IProps {
  tag: string
  isExpanded: boolean
  toggleExpand: (tag: string) => void
  notes: IDefaultNote[]
  handleNoteCreate: (data: object) => void
  handleNoteUpdate: (id: string, data: object) => void
  handleNoteMove: (tag: string, dragIndex: number, hoverIndex: number) => void
  handleNoteDrop: (IDefaultNote: IDefaultNote, dragOrder: number) => void
  handleNoteDelete: (id: string) => void
  isNoteBeingDragged: boolean
}

const UWDefaultNotesSection = ({
  tag,
  isExpanded,
  toggleExpand,
  notes,
  handleNoteCreate,
  handleNoteUpdate,
  handleNoteMove,
  handleNoteDrop,
  handleNoteDelete,
  isNoteBeingDragged,
}: IProps) => {
  const [closeMenuCount, setCloseMenuCount] = useState(0)

  const handleExpand = useCallback(() => {
    toggleExpand(tag)
  }, [toggleExpand, tag])

  const handleAddNote = useCallback(async () => {
    setCloseMenuCount((prev) => prev + 1)
    await handleNoteCreate({
      tag,
    })
  }, [tag, handleNoteCreate])

  const handleSectionNoteMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      handleNoteMove(tag, dragIndex, hoverIndex)
    },
    [handleNoteMove, tag],
  )

  return (
    <div className={styles.tagSection}>
      <div className={styles.tagSectionHeader}>
        <div className={styles.tagSectionTitle} onClick={handleExpand}>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
          {tag}
        </div>

        <Menu closeMenuCount={closeMenuCount}>
          <MenuItem onClick={handleAddNote}>Add Note</MenuItem>
        </Menu>
      </div>

      {isExpanded && (
        <div className={styles.tagSectionNotes}>
          {notes?.map((note, index) => (
            <UWDefaultNotesItem
              key={note.id}
              note={note}
              index={index}
              handleNoteUpdate={handleNoteUpdate}
              handleNoteMove={handleSectionNoteMove}
              handleNoteDrop={handleNoteDrop}
              handleNoteDelete={handleNoteDelete}
              isNoteBeingDragged={isNoteBeingDragged}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default UWDefaultNotesSection
