import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Field, Form } from 'react-final-form'
import cn from 'classnames'

import styles from './Treasury.module.scss'
import genericSs from '@styles/generic.module.scss'

import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import { ILoadingData } from '../../redux/types'
import { ITreasuryData, ITreasuryDetails, TreasuryAmountTypes } from '@common/interfaces/treasury'
import CurrencyField from '../Common/CurrencyField'
import AddButton from '../Client/AddButton'
import Modal from '../Common/Modal'
import Button from '../Common/Button'
import SelectField from '../Common/SelectField'
import InputLabel from '../Common/InputLabel'
import TextField from '../Common/TextField'
import Autocomplete from '../Common/Autocomplete'
import { ReactComponent as EmptyIcon } from '@assets/images/empty-page-icon.svg'
import Card from '../Common/Card'
import TreasuryTable from './TreasuryTable'
import {
  TREASURY_FUNDING_WIRES_FILTERS_CONFIG,
  TREASURY_OTHER_WIRES_FILTERS_CONFIG,
  TREASURY_PARTICIPATION_WIRES_FILTERS_CONFIG,
  TREASURY_PASSTHROUGH_WIRES_FILTERS_CONFIG,
} from '@common/constants/filters'

interface IProps {
  listTreasury: (params?: object) => void
  listTreasuryFundingWires: (params?: object) => void
  listTreasuryOtherWires: (params?: object) => void
  listTreasuryParticipationWires: (params?: object) => void
  listTreasuryPassthroughWires: (params?: object) => void
  treasuryData: ILoadingData<ITreasuryData>
  fundingWires: ILoadingData<{ data: ITreasuryDetails[] }>
  otherWires: ILoadingData<{ data: ITreasuryDetails[] }>
  participationWires: ILoadingData<{ data: ITreasuryDetails[] }>
  passthroughWires: ILoadingData<{ data: ITreasuryDetails[] }>
  updateTreasury: (params: object) => void
  createTreasury: (params: object) => void
}

const Treasury = ({
  listTreasury,
  listTreasuryFundingWires,
  listTreasuryOtherWires,
  listTreasuryParticipationWires,
  listTreasuryPassthroughWires,
  treasuryData,
  fundingWires,
  otherWires,
  participationWires,
  passthroughWires,
  updateTreasury,
  createTreasury,
}: IProps) => {
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [fundingRefreshCounter, setFundingRefreshCounter] = useState(0)
  const [otherRefreshCounter, setOtherRefreshCounter] = useState(0)

  const { data: treasurySummaryData, clientList } = useMemo(() => {
    return {
      data: treasuryData?.data?.data,
      isLoading: treasuryData?.isLoading,
      clientList: treasuryData?.data?.data?.clientList,
    }
  }, [treasuryData])

  const newWireTypes = useMemo(() => {
    return [TreasuryAmountTypes.Funding, TreasuryAmountTypes.Other].map((type) => ({
      value: type,
      label: type,
    }))
  }, [])

  useEffect(() => {
    listTreasury()
  }, [listTreasury])

  const handelCreateWire = useCallback(
    async ({ newWireType, newWireAmount, newWireClient, newWireDescription }) => {
      setIsSaving(true)

      await createTreasury({
        type: newWireType,
        amount: newWireAmount,
        description: newWireDescription,
        clientName: newWireClient?.value,
      })
      await listTreasury({ skipLoader: true })
      if (newWireType === TreasuryAmountTypes.Other) {
        setOtherRefreshCounter((refreshCounter) => refreshCounter + 1)
      } else {
        setFundingRefreshCounter((refreshCounter) => refreshCounter + 1)
      }
      setIsSaving(false)
      setIsCreateModalShown(false)
    },
    [listTreasury, createTreasury],
  )

  const selectedWiresTotal = useMemo(
    () =>
      (fundingWires?.data?.data || [])
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      (passthroughWires?.data?.data || [])
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      (participationWires?.data?.data || [])
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0) +
      (otherWires?.data?.data || [])
        ?.filter((item) => item.isChecked)
        ?.reduce((acc, item) => acc + Number(item.amount), 0),
    [fundingWires, passthroughWires, participationWires, otherWires],
  )

  const endingCash = useMemo(
    () => treasurySummaryData?.startingCash + treasurySummaryData?.finalDraw - selectedWiresTotal,
    [treasurySummaryData, selectedWiresTotal],
  )

  const handleUpdateTreasuryData = useCallback(
    async (data: object) => {
      await updateTreasury(data)
      await listTreasury({ skipLoader: true })
    },
    [updateTreasury, listTreasury],
  )

  const toggleModal = useCallback(() => {
    setIsCreateModalShown(!isCreateModalShown)
  }, [isCreateModalShown])

  const handleUpdateDebounce = useMemo(
    () =>
      debounceEventHandler(async (data: object) => {
        await handleUpdateTreasuryData(data)
      }, 1000),
    [handleUpdateTreasuryData],
  )

  const handleUpdateStartingCash = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        startingCash: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const handleUpdateFinalDraw = useCallback(
    ({ target }) => {
      handleUpdateDebounce({
        finalDraw: target.value || 0,
      })
    },
    [handleUpdateDebounce],
  )

  const onFormSubmit = useCallback(
    (formValues) => {
      const { newWireType, newWireDescription, newWireClient, newWireAmount } = formValues
      handelCreateWire({ newWireType, newWireDescription, newWireClient, newWireAmount })
    },
    [handelCreateWire],
  )

  if (!treasurySummaryData?.id) {
    return (
      <div className={styles.treasuryNotReady}>
        <EmptyIcon />
        <div className={styles.treasuryNotReadyTitle}>Treasury will be available soon</div>
      </div>
    )
  }

  return (
    <Box py={2}>
      <Grid container rowSpacing={0.5} columnSpacing={4.5}>
        <Grid item xs={12}>
          <div className={styles.titleContainer}>
            <AddButton className={styles.addButton} onClick={toggleModal} />
          </div>
        </Grid>

        <Grid item xs={6} gap={2} display="flex" flexDirection="column">
          <Card noHeaderMargin>
            <div className={styles.title}>Today's Cash</div>

            <div className={styles.summary}>
              <div className={styles.summaryRow}>
                <div className={styles.summaryRowLabel}>Starting Cash</div>
                <div className={styles.summaryRowValue}>
                  <CurrencyField
                    size="small"
                    className={styles.amountField}
                    name="startingCash"
                    value={formatPrice(treasurySummaryData?.startingCash)}
                    onChange={handleUpdateStartingCash}
                    useFinalForm={false}
                  />
                </div>
              </div>

              <div className={styles.summaryRow}>
                <div className={styles.summaryRowLabel}>-Estimated Cash Out</div>
                <div className={styles.summaryRowValue}>${formatPrice(selectedWiresTotal)}</div>
              </div>

              <div className={styles.summaryRow}>
                <div className={styles.summaryRowLabel}>+ Final Draw Amount</div>
                <div className={styles.summaryRowValue}>
                  <CurrencyField
                    size="small"
                    className={styles.amountField}
                    name="buffer"
                    value={formatPrice(treasurySummaryData?.finalDraw)}
                    onChange={handleUpdateFinalDraw}
                    useFinalForm={false}
                  />
                </div>
              </div>

              <div className={cn(styles.summaryRow, styles.summaryRowWithBorder)}>
                <div className={styles.summaryRowLabel}>Ending Cash</div>
                <div className={styles.summaryRowValue}>${formatPrice(endingCash)}</div>
              </div>
            </div>
          </Card>

          <TreasuryTable
            title="Fundings"
            wireType={TreasuryAmountTypes.Funding}
            config={TREASURY_FUNDING_WIRES_FILTERS_CONFIG}
            wires={fundingWires}
            listWires={listTreasuryFundingWires}
            updateTreasury={updateTreasury}
            refreshCounter={fundingRefreshCounter}
          />
        </Grid>

        <Grid item xs={6} gap={2} display="flex" flexDirection="column">
          <TreasuryTable
            title="Passthroughs"
            wireType={TreasuryAmountTypes.Passthrough}
            config={TREASURY_PASSTHROUGH_WIRES_FILTERS_CONFIG}
            wires={passthroughWires}
            listWires={listTreasuryPassthroughWires}
            updateTreasury={updateTreasury}
          />

          <TreasuryTable
            title="Participations"
            wireType={TreasuryAmountTypes.Participation}
            config={TREASURY_PARTICIPATION_WIRES_FILTERS_CONFIG}
            wires={participationWires}
            listWires={listTreasuryParticipationWires}
            updateTreasury={updateTreasury}
          />

          <TreasuryTable
            title="Other"
            wireType={TreasuryAmountTypes.Other}
            config={TREASURY_OTHER_WIRES_FILTERS_CONFIG}
            wires={otherWires}
            listWires={listTreasuryOtherWires}
            updateTreasury={updateTreasury}
            refreshCounter={otherRefreshCounter}
          />
        </Grid>

        {isCreateModalShown && (
          <Modal
            open={isCreateModalShown}
            onCancel={toggleModal}
            classes={{ root: styles.modalRoot }}
            title="Add Wire"
          >
            <Form
              onSubmit={onFormSubmit}
              render={({ handleSubmit, values }) => {
                const isSaveDisabled =
                  !values.newWireType ||
                  (!values.newWireAmount && values.newWireType === TreasuryAmountTypes.Other) ||
                  (!values.newWireDescription &&
                    values.newWireType === TreasuryAmountTypes.Other) ||
                  (!values.newWireClient && values.newWireType === TreasuryAmountTypes.Funding)

                return (
                  <form onSubmit={handleSubmit}>
                    <Box className={styles.fieldsContainer}>
                      <InputLabel htmlFor="newWireType" className={genericSs.textLeft}>
                        Type
                      </InputLabel>
                      <Field
                        name="newWireType"
                        render={({ input }) => (
                          <SelectField
                            {...input}
                            options={newWireTypes}
                            className={styles.selectFormField}
                            variant="outlined"
                            useFinalForm={false}
                            fullWidth
                          />
                        )}
                      />

                      {values.newWireType === TreasuryAmountTypes.Other && (
                        <>
                          <InputLabel htmlFor="newWireDescription" className={genericSs.textLeft}>
                            Recipient
                          </InputLabel>
                          <Field
                            name="newWireDescription"
                            render={({ input }) => (
                              <TextField {...input} className={styles.textFormField} />
                            )}
                          />

                          <InputLabel htmlFor="newWireAmount" className={genericSs.textLeft}>
                            Amount
                          </InputLabel>
                          <CurrencyField className={styles.textFormField} name="newWireAmount" />
                        </>
                      )}

                      {values.newWireType === TreasuryAmountTypes.Funding && (
                        <>
                          <InputLabel htmlFor="newWireClient" className={genericSs.textLeft}>
                            Client
                          </InputLabel>
                          <Autocomplete
                            name="newWireClient"
                            options={clientList}
                            className={styles.autocompleteField}
                          />
                        </>
                      )}
                    </Box>
                    <Button
                      disabled={isSaveDisabled}
                      isLoading={isSaving}
                      color="primary"
                      variant="contained"
                      fullWidth
                      small={false}
                      type="submit"
                    >
                      Save
                    </Button>
                  </form>
                )
              }}
            />
          </Modal>
        )}
      </Grid>
    </Box>
  )
}

export default Treasury
